/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createContext, useCallback, useContext, useState } from 'react';

import { IFCWithChildren } from '~/interfaces/i-fc-with-children';
import { useClickOutside } from '~/hooks/use-click-outside';
import { Menu } from '~/components/menu';

interface IMenuProps {
  readonly open: boolean;
  readonly switchMenu: () => void;
}

const MenuContext = createContext<IMenuProps>({} as IMenuProps);
const MenuProvider: IFCWithChildren = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);

  const switchMenu = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const menuRef = useClickOutside<HTMLDivElement>(() => setOpen(false));

  return (
    <MenuContext.Provider value={{ open, switchMenu }}>
      <Menu menuRef={menuRef} />
      {children}
    </MenuContext.Provider>
  );
};

const useMenu = () => useContext(MenuContext);
export { useMenu, MenuProvider };
