import { FC } from 'react';

import { IoMdClose } from 'react-icons/io';
import { HiMenuAlt4 } from 'react-icons/hi';

import Image from 'next/image';

import { useMenu } from '~/providers/menu';
import { useLayoutType } from '~/hooks/use-layout-type';
import { colors } from '~/constants/colors';

import { HeaderContainer } from './styles';
import logo from '../../../public/logo.png';
import logoMobile from '../../../public/logo_mobile.png';
import fullLogo from '../../../public/logo_full.png';

interface IHeaderProps {
  readonly onMenu?: boolean;
}
export const Header: FC<IHeaderProps> = ({ onMenu }) => {
  const isMobile = useLayoutType('mobile');
  const { switchMenu, open } = useMenu();

  const MenuIcon = onMenu ? IoMdClose : HiMenuAlt4;

  return (
    <HeaderContainer style={{ opacity: open && !onMenu ? 0 : 1 }}>
      <Image alt="logo" src={logo} />

      {isMobile ? (
        <Image alt="full logo" src={logoMobile} width={200} />
      ) : (
        <Image alt="full logo" src={fullLogo} />
      )}

      <MenuIcon
        size={28}
        color={colors.green}
        style={{ cursor: 'pointer' }}
        onClick={switchMenu}
      />
    </HeaderContainer>
  );
};
