import { useState, useEffect } from 'react';

type LayoutType = 'mobile' | 'desktop';
export function useLayoutType(type: LayoutType): boolean {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const query =
      type === 'mobile' ? '(max-width: 750px)' : '(min-width: 750px)';

    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    const listener = (): void => {
      setMatches(media.matches);
    };

    media.addEventListener('change', listener);

    return () => media.removeEventListener('change', listener);
  }, [matches, type]);

  return matches;
}
