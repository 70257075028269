export const colors = {
  background: 'rgb(22, 22, 22)',
  black: 'rgb(0, 0, 0)',
  green: 'rgb(103, 237, 53)',
  light_green: 'rgb(64, 157, 122)',
  text_white: 'rgb(255,255,255)',
  blue: 'rgb(0, 24, 238)',
  light_blue: 'rgb(13,153,255)',
  purple: 'rgb(119,131,245)',
};
