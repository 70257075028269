/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { FC, MutableRefObject, useCallback, useState } from 'react';

import { useRouter } from 'next/router';

import { useMenu } from '~/providers/menu';
import { useLayoutType } from '~/hooks/use-layout-type';
import {
  RoutesEnum,
  RoutesFormattedSummaryEnum,
  RoutesSummaryEnum,
} from '~/enums/routes';
import {
  PillarsSubmenuEnum,
  PillarsSubmenuFormattedEnum,
} from '~/enums/pillars-submenu';

import {
  MenuContainer,
  MenuOption,
  MenuWrapper,
  PillarsSubmenu,
} from './styles';
import { Header } from '../header';

interface IMenuProps {
  readonly menuRef: MutableRefObject<HTMLDivElement>;
}
export const Menu: FC<IMenuProps> = ({ menuRef }) => {
  const isMobile = useLayoutType('mobile');
  const { open, switchMenu } = useMenu();
  const router = useRouter();
  const [showPillarsSubmenu, setShowPillarsSubmenu] = useState<boolean>(false);

  const switchPillarsSubmenu = useCallback(() => {
    setShowPillarsSubmenu((prev) => !prev);
  }, []);

  const handleRouteClick = useCallback(
    (newRoute: RoutesSummaryEnum | RoutesEnum) => {
      const fixed = RoutesEnum[newRoute];
      if (newRoute === RoutesSummaryEnum.pillars) {
        switchPillarsSubmenu();
      } else {
        router.push(fixed);
        switchMenu();
      }
    },
    [router, switchPillarsSubmenu, switchMenu]
  );

  return (
    <MenuContainer
      ref={menuRef}
      style={{
        top: open ? 0 : '-303px',
        pointerEvents: open ? 'all' : 'none',
      }}
    >
      <Header onMenu />

      <MenuWrapper>
        <ul
          {...(isMobile && {
            style: {
              transition: '0.25s',
              width: showPillarsSubmenu ? 0 : '100%',
              pointerEvents: showPillarsSubmenu ? 'none' : 'all',
            },
          })}
        >
          {Object.entries(RoutesFormattedSummaryEnum).map(([route, title]) => (
            <MenuOption
              active={router.asPath === RoutesEnum[route]}
              key={route}
              onClick={() => handleRouteClick(route as RoutesSummaryEnum)}
              {...(isMobile && {
                style: {
                  transition: '0.15s',
                  opacity: showPillarsSubmenu ? 0 : 1,
                },
              })}
            >
              {`${route === RoutesSummaryEnum.pillars ? `${title} > ` : title}`}
            </MenuOption>
          ))}
        </ul>

        <PillarsSubmenu>
          {[1, 2, 3, 4, 5].map((index) => {
            const breakIndex = isMobile ? 2 : 3;
            if (index < breakIndex) {
              return <li key={index} style={{ height: '38.4px' }} />;
            }

            const submenuList = isMobile
              ? {
                  2: PillarsSubmenuEnum.digital_assessment,
                  3: PillarsSubmenuEnum.digital_blueprint,
                  4: PillarsSubmenuEnum.performance_racking,
                  5: undefined,
                }
              : {
                  3: PillarsSubmenuEnum.digital_assessment,
                  4: PillarsSubmenuEnum.digital_blueprint,
                  5: PillarsSubmenuEnum.performance_racking,
                };

            const key = isMobile
              ? {
                  2: 1,
                  3: 2,
                  4: 3,
                  5: null,
                }
              : {
                  3: 1,
                  4: 2,
                  5: 3,
                };

            const submenu = submenuList[index];

            return (
              <MenuOption
                active={
                  router.asPath.replace('/', '') ===
                  (submenu as string)?.replace('_', '-')
                }
                onClick={() =>
                  !isMobile || index !== 5
                    ? handleRouteClick(submenu as RoutesEnum)
                    : switchPillarsSubmenu()
                }
                key={index}
              >
                {!isMobile || index !== 5
                  ? `${key[index]}. ${PillarsSubmenuFormattedEnum[submenu]}`
                  : `< voltar`}
              </MenuOption>
            );
          })}
        </PillarsSubmenu>
      </MenuWrapper>
    </MenuContainer>
  );
};
