import styled from 'styled-components';
import { spacing } from '~/constants/spacing';

export const HeaderContainer = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: ${spacing.sm};
  padding-bottom: ${spacing.sm};
  transition: 0.5s;

  @media (max-width: 750px) {
    padding-left: ${spacing.md};
    padding-right: ${spacing.md};
  }

  @media (min-width: 750px) {
    padding-left: ${spacing.lg};
    padding-right: ${spacing.lg};
  }
`;
