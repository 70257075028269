import styled, { css } from 'styled-components';
import { spacing } from '~/constants/spacing';
import { colors } from '~/constants/colors';

export const MenuContainer = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: 0.5s;
  z-index: 100;
  background-color: ${colors.background};
`;

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: ${spacing.lg};
  margin-top: ${spacing.md};

  @media (max-width: 750px) {
    padding-left: ${spacing.md};
    width: 200%;
  }

  ul {
    @media (max-width: 750px) {
      width: 100%;
    }

    li {
      color: ${colors.text_white};
      font-size: 24px;

      @media (max-width: 750px) {
        font-size: 20px;
      }

      line-height: 38.4px;
      transition: 0.25s;
      text-transform: uppercase;
      min-width: 100%;
      white-space: nowrap;
      cursor: pointer;
      user-select: none;
      &:hover {
        color: ${colors.green};
        text-decoration: underline !important;
      }
    }
  }
`;

export const PillarsSubmenu = styled.ul`
  @media (min-width: 750px) {
    margin-left: ${spacing.lg};
  }
`;

interface IMenuOptionProps {
  readonly active: boolean;
}
export const MenuOption = styled.li<IMenuOptionProps>`
  ${({ active }) =>
    active &&
    css`
      color: ${colors.green} !important;
    `}
`;
