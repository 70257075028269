import { createGlobalStyle } from 'styled-components';

import { colors } from './constants/colors';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    outline: 0;
    padding: 0;
    font-family: 'Hanken Grotesk';
    box-sizing: border-box;
  }

  html, body, #__next {
    width: 100%;
    height: 100%;
    letter-spacing: 0.5px;
    font-weight: 400;
    overflow-x: hidden;
    background-color: ${colors.background};
  }

  #root {
    height: 100%;
    background-color: ${colors.background};
    overflow-y: auto;
  }

  button {
    border: none;
    background: none;
    font-weight: bold;
    cursor: pointer;
  }

  #__next {
    z-index: 0;
    > main {
      height: 100%;
    }
  }

  h1, h2, h3, h4, h5, h6, strong, b, span, p {
    color: ${({ theme }) => theme.text};
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  @media print {
    body {
      overflow: visible;
    }
  }
`;
